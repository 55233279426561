import React from "react";
import styled from "styled-components";
import { formatSparkFromToTime } from "../utils/dateTimeUtils";
import { ReactComponent as LocationPin } from '../resources/images/location-pin.svg';
import { ReactComponent as Clock } from '../resources/images/clock.svg';
import { ReactComponent as Pound } from '../resources/images/pound-icon.svg';
import {ReactComponent as ChargeableIcon} from '../resources/images/chargeable-icon.svg';
import { store } from "../App";
import { DefaultButton } from "../resources/styles";

const Tile = styled.div`
    border: 1px solid #ddd;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;

    .content{
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      button{
        margin: auto 0 0 0;
        padding: 0.75rem;
      }
    }

    .content{
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      button{
        margin: auto 0 0 0;
        padding: 0.75rem;
      }
    }

    .fill-icon {
        fill: ${props => props.theme.buttonBackground || '#6B207D'};
        stroke: ${props => props.theme.buttonBackground || '#6B207D'};
    }
        
    p {
      font-size: 1rem;
      font-weight: ${props => props.isKioskOrSignage ? "bold" : "normal"};
    }
    
    .chargeable{
      width: 3.75rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 0.75rem;
      background-color: ${props => props.theme.buttonBackground || '#6B207D'};
    }

    .title{
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: ${props => props.theme.headerColor || "#5a5a5a"};
    }

    .image{
      width: 100%;
      object-fit: cover;
      aspect-ratio: 2;
    }

    .chargeable-icon{
      width: 2rem;
      height: 2rem;
      fill: #fff;
      stroke: #fff;
    }
`;


const EventTile = ({ event, handleClick, isKioskOrSignage }) => {
    const { name, venue, images, imageId, start, end, attributes=[] } = event;
    const hubUrl = store.getState().configuration.hubUrl;

    let image = "";
    if(imageId){
        image = hubUrl + `blobs/image?reference=${imageId}`; // Event from hub (scheduled programme)
    }else{
      let imageObject = images.find(obj => obj.types.includes("Preview")); // Event from Spark
      image = imageObject?.url?.replace("://go.", "://app.") || "";
    }

    let isChargeableEvent = attributes.includes("Chargeable Experience");

    return (
      <Tile className='cell' isKioskOrSignage={isKioskOrSignage}>
        {isChargeableEvent && (
          <div className='absolute top-0 left-0 chargeable pound'>
            <ChargeableIcon className="chargeable-icon"/>
          </div>
        )}
        <img src={image || `/images/spark_event_image_small.jpg`} alt={name} className="image" />
        <div className='content p-4'>
          <h3 className="title">{name}</h3>
          <div className='flex items-center mb-2'>
            <Clock className="fill-icon w-6 h-6 align-middle mr-2 inline" />
            {(start || end) ? (
                <p className="m-0">{start} {end && `- ${end}`}</p>
            ) : (
                <p className='m-0'>{formatSparkFromToTime(event)}</p>
            )}
          </div>
          {venue && (
            <div className={`flex items-center ${isChargeableEvent ? "mb-2": "mb-6"}`}>
              <LocationPin className="fill-icon w-6 h-6 align-middle mr-2 inline" />
              <p className='m-0'>{venue}</p>
            </div>
          )}
          {isChargeableEvent && (
            <div className='flex items-center mb-6'>
              <Pound className="fill-icon w-6 h-6 align-middle inline"/>
              <p className="m-0 px-2">Chargeable Event</p>
            </div>
          )}
          <DefaultButton className="w-full" onClick={handleClick}>View More</DefaultButton>
        </div>
      </Tile>
    );
};

export default EventTile;