import axios from "axios/index";
import browserHistory from "../utils/history";
import {store} from '../App';
import {formatLocaleLong, getSelectedCurrency} from "../translations/translationUtils";
import {saveSeanetVodToken} from "./seanetVodActions";

export function fetchFromHub(path, successCallback, errorCallback = function(message){}) {
    return async function (dispatch, getStore) {
        const sessionId = getStore().authentication.sessionId;

        const securedOrigin = window._securedOrigin_ === 'true' ? true : false;

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        if (sessionId) {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'SessionId': sessionId,
            };
        }

        return axios({
            method: 'GET',
            url: getStore().configuration.hubApiUrl + path,
            headers: headers,
            timeout: 1000 * 15,
            withCredentials: securedOrigin
        })
        .then(function (response) {
            successCallback(response.data, dispatch);
        })
        .catch(function (error) {
            if (error.response && error.response.status === 403) {
                dispatch({type: "CLEAR_AUTHENTICATION"});
                browserHistory.push("/login");
                return;
            }
            errorCallback(error.message, error.code);
        });
    };
}

export function postToHub(path, payload, successCallback, errorCallback = function (message) {
}) {
    return async function (dispatch, getStore) {

        const securedOrigin = window._securedOrigin_ === 'true' ? true : false;

        return axios({
            method: 'POST',
            url: getStore().configuration.hubApiUrl + path,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'SessionId': getStore().authentication.sessionId,
            },
            data: payload,
            withCredentials: securedOrigin
        })
            .then(function (response) {
                successCallback(response.data, dispatch);
            })
            .catch(function (error) {
                if (error.response.status === 403) {
                    dispatch({type: "CLEAR_AUTHENTICATION"});
                    browserHistory.push("/login");
                    return;
                }

                errorCallback(error.message, error.code);
            });
    }
}

export function getFromMusement(path, successCallback, errorCallback, limitToRiver) {
    return musementRequest('GET', path, null, successCallback, errorCallback, limitToRiver);
}

export function postToMusement(path, payload, successCallback, errorCallback, limitToRiver) {
    return musementRequest('POST', path, payload, successCallback, errorCallback, limitToRiver);
}

export function putToMusement(path, payload, successCallback, errorCallback, limitToRiver) {
    return musementRequest('PUT', path, payload, successCallback, errorCallback, limitToRiver);
}

export function deleteFromMusement(path, successCallback, errorCallback, limitToRiver) {
    return musementRequest('DELETE', path, null, successCallback, errorCallback, limitToRiver);
}

async function musementRequest(method, path, payload, successCallback, errorCallback, limitToRiver = false) {
    //TODO - change the url to be fetching from hubApi and include the SessionId header after hub endpoints fully working and tested
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': formatLocaleLong(),
        'X-Musement-Currency': getSelectedCurrency(),
        'x-musement-version': "3.4.0",
        'x-musement-source': "rivercruises",


    }
    if (limitToRiver) {
        headers['X-Musement-Market'] = "cs-rc";
    }
    return networkRequest({
        path,
        method,
        payload,
        successCallback,
        errorCallback,
        baseUrl: "https://cruises-api.prod.musement.com/",
        headers,
        withCredentials: false,
    });
}

async function networkRequest(options) {
    try {
        const securedOrigin = options.withCredentials !== undefined ? options.withCredentials : window._securedOrigin_ === 'true';
        const response = await axios({
            method: options.method,
            url: (options.baseUrl ? options.baseUrl : store.getState().configuration.hubApiUrl) + options.path,
            headers: options.headers || {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'SessionId': store.getState().authentication.sessionId,
            },

            data: options.payload || null,
            withCredentials: securedOrigin,
        });

        if(options.successCallback) {
            options.successCallback(response.data);
        }

        return response.data;
    }
    catch (error) {
        console.error("Network request, network error", error);

        // only logout user if 403 error comes from the hub
        if(!options.baseUrl) {
            if (error && error.response && error.response.status === 403) {
                store.dispatch({type: "CLEAR_AUTHENTICATION"});
                if(browserHistory?.location?.pathname !== "/login") {
                    browserHistory.push("/login");
                }
                return;
            }
        }

        if(options.errorCallback) {
            options.errorCallback(error.message, error.code);
        }
    }
}

export function getFromHubNew(path, successCallback, errorCallback) {
    return networkRequest({method: 'GET', path, successCallback, errorCallback});
}

export function postToHubNew(path, payload, successCallback, errorCallback) {
    return networkRequest({method: 'POST', path, payload, successCallback, errorCallback});
}

export function putToHubNew(path, payload, successCallback, errorCallback) {
    return networkRequest({method: 'PUT', path, payload, successCallback, errorCallback});
}

export function deleteFromHubNew(path, successCallback, errorCallback) {
    return networkRequest({method: 'DELETE', path, successCallback, errorCallback});
}

export function getImageUrlById(imageId, {width = 0, category = ""} = {}) {
    const baseUrl = store.getState().configuration.hubUrl;
    let imageUrl = `${baseUrl}blobs/image?id=${imageId}`;

    if (width) {
        imageUrl += `&width=${width}`;
    }

    if (category) {
        imageUrl += `&category=${category}`;
    }

    return imageUrl;
}

export function getResourceImageUrl(resourceId, {width = 0, category = ""} = {}) {
    const baseUrl = store.getState().configuration.hubUrl;
    let imageUrl = `${baseUrl}blobs/image?reference=${resourceId}`;

    if (width) {
        imageUrl += `&width=${width}`;
    }

    if (category) {
        imageUrl += `&category=${category}`;
    }

    return imageUrl;
}

export async function getDailyScheduleByGoSpark(date, days) {
    const sparkUrl = store.getState().configuration?.hubSettings?.uiSettings?.sparkUrl || "https://go.staging.ambdev.uk";
    const token = store.getState().configuration?.hubSettings?.uiSettings?.sparkApiToken || '6LojH4KcsWTSjqutbC5$LMTO';
    const propertyID = store.getState().configuration?.hubSettings?.uiSettings?.sparkPropertyId || '641a2710e9fb164d1ad6bf55';

    try {
        const response = await axios({
            method: "GET",
            url: `${sparkUrl}/api/v1/ext/export/${propertyID}/schedule?date=${date}${days ? `&days=${days}` : ""}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        return response.data;
    }
    catch (error) {
        console.error("spark network request, network error", error);
    }
}

export async function getFromSeanet(path) {
    const seanetUrl = getSeanetUrl();

    let token = store.getState().seanetVod?.token;
    const tokenValidUntil = store.getState().seanetVod?.tokenValidUntil;

    if(!token || new Date().getTime() > tokenValidUntil) {
        token = await getSeanetToken();
    }

    try {
        const response = await axios({
            method: "GET",
            url: `${seanetUrl}${path}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        return response.data;
    }
    catch (error) {
        console.error("seanet network request, network error", error);
    }
}

export async function getSeanetToken() {
    const seanetUrl = getSeanetUrl();

    try {
        const response = await axios({
            method: "POST",
            url: `${seanetUrl}api/connect-token`,
            headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // TODO - make this data dynamic coming from hub or better yet have hub return the token and handle the login logic
            data: {
                username: "player@digitech.world",
                password: "4D$3Vg6#q9"
            }
        });

        const token = response.data?.token;

        saveSeanetVodToken(token);

        return token;
    }
    catch (error) {
        console.error("seanet connect token error, network error", error);
    }
}

export function getSeanetUrl() {
    return store.getState().vodconfiguration?.url || "https://vod.sea-net.de/";
}