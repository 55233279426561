import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getShortDayOfWeek } from '../utils/dateTimeUtils';
import { device } from '../resources/styles';

const NavigationWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    gap: 0.35rem;
    width: 100%;
    max-width: 550px;
    @media ${device.tablet} {
        gap: 0.65rem;
    }

    @media (min-width: 1280px) {
        max-width: 700px;
        gap: 0.8rem;
    }
`;

const ButtonStyles = styled.button`
    font-weight: ${props => props.theme.buttonFontWeight || 'bold'};
    padding: ${props => props.padding || '1em'};
    display: inline-block;
    background: ${props => props.selected ? props.theme.buttonBackground : props.theme.buttonColor || 'white'};
    color: ${props => props.selected ? props.theme.buttonColor : props.theme.buttonBackground || '6B207D'};
    border: ${props => props.theme.buttonBorder || 'none'};
    box-shadow: ${props => props.theme.buttonBoxShadow || 'none'};
    text-transform: uppercase;
    line-height: 1;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    text-decoration: none;
    position: relative;

    :disabled {
        background-color: #e0e0e0;
        pointer-events: none;
    }   
`;

const NavigateButton = styled(ButtonStyles)`
    padding: 0.5rem;

    @media ${device.largePhone} {
        padding: 0.5rem 0.75rem;
    }

    @media ${device.tablet} {
        padding: 0.5rem 1.2rem;
    }
`;

const DatesWrapper = styled.div`
    display: flex;
    gap: 0.35rem;
    overflow: hidden;
    flex-grow: 1;
    justify-content: center;
    @media ${device.tablet} {
        gap: 0.65rem;
    }

    @media (min-width: 1280px) {
        gap: 0.8rem;
    }
`;

const DateButton = styled(ButtonStyles)`
    padding: 0.25rem;
    transition: transform 0.5s ease;
    width: calc(100% / 7);

    .month{
        display: none;
    }

    &:hover {
        background: ${props => props.theme.buttonBackground || 'white'};
        color: ${props => props.theme.buttonColor || '6B207D'};
    }

    > p {
        margin: 0;
        text-align: center;
        font-size: 0.8rem;
    }

    @media ${device.largePhone} {
        padding: 0.5rem 0;
        > p {
            font-size: 1rem;
        }
        .date{
            font-size: 0.8rem;
        }
        .month{
            display: inline;
        }
    }
    @media (min-width: 500px){
        padding: 0.5rem;
        .date{
            font-size: 1rem;
        }
    }
    
    @media (min-width: 1280px) {
        padding: 0.7rem 0;
        > p {
            font-size: 1.15rem;
        }
    }
`;

const DateNavigator = ({ dates, onSelectionChange }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        onSelectionChange(selectedIndex);
    // eslint-disable-next-line
    }, []);

    const handleDateClick = (index) => {
        if(selectedIndex === index) return;
        onSelectionChange(index);
        setSelectedIndex(index);
    };

    const handlePrevClick = () => {
        if(selectedIndex === 0) return;
        onSelectionChange(selectedIndex-1);
        setSelectedIndex(selectedIndex-1);
    };

    const handleNextClick = () => {
        if(selectedIndex === dates.length-1) return;
        onSelectionChange(selectedIndex+1);
        setSelectedIndex(selectedIndex+1);
    };

    let startDateIndex = Math.floor(selectedIndex / 7) * 7;
    let endDateIndex = startDateIndex + 6 ;
    if(endDateIndex > dates.length-1){
        const diff =  endDateIndex - (dates.length - 1);
        endDateIndex = endDateIndex - diff;
        startDateIndex = (startDateIndex - diff >= 0) ? startDateIndex-diff : 0;
    }
    const currentBatchDates = dates.slice(startDateIndex, endDateIndex+1);

    return (
        <NavigationWrapper>
            <NavigateButton onClick={handlePrevClick} disabled={selectedIndex === 0}>
                <i className="fa fa-chevron-left" />
            </NavigateButton>
            <DatesWrapper>
                {currentBatchDates?.map((date, index) => {
                    let adjustedIndex = startDateIndex + index;
                    let [day, month] = date.date.split('/');
                    return (
                        <DateButton
                            key={date.id}
                            onClick={() => handleDateClick(adjustedIndex)}
                            selected={selectedIndex === adjustedIndex}
                        >
                            <p>{getShortDayOfWeek(date.date)}</p>
                            <p className='date'>{day}<span className='month'>/{month}</span></p>
                        </DateButton>
                    );
                })}
            </DatesWrapper>
            <NavigateButton onClick={handleNextClick} disabled={selectedIndex === dates.length - 1}>
                <i className="fa fa-chevron-right" />
            </NavigateButton>
        </NavigationWrapper>
    );
};

export default DateNavigator;