import React, { useCallback, useState, useMemo } from 'react';
import {useGet} from "../../hooks/useGet";
import {convertToFormattedDate, formatDMYtoYMD, isDateTodayOrFuture} from "../../utils/dateTimeUtils";
import DateNavigator from '../../components/DateNavigator';
import styled from 'styled-components';
import { getTranslation } from '../../translations/translationUtils';
import GoBySparkDetail from './GoBySparkDetail';
import { getDailyScheduleByGoSpark } from '../../actions/networkActions';
import { device } from '../../resources/styles';
import FullScreenLoader from '../../components/FullScreenLoader';

const Header = styled.h1`
    color: ${props => props.theme.headerColor || "#5a5a5a" };
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.p`
    color: ${props => props.theme.headerColor || "#5a5a5a" };
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;

    @media ${device.largePhone} {
        font-size: 1.3rem;
    }
`;

function GoBySparkOverview() {

    const [selectedDay, setSelectedDay] = useState(null);
    const [events, setEvents] = useState({});
    const [loading, setLoading] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState(null);

    const {isLoading, error, data: dates} = useGet("itinerary/schedules");

    const activeDates = useMemo(()=>{
        return dates?.filter(date => isDateTodayOrFuture(date.date));
    },[dates]);

    const fetchData = useCallback(async (date, days) => {
        setLoading(true);
        try {
            let eventsByDates = await getDailyScheduleByGoSpark(date, days); // get dates in batch of 7
            eventsByDates = eventsByDates?.lineup || [];
            let events = {};
            eventsByDates.forEach(eventDay => {
                events[eventDay.date] = {
                    events: eventDay.events,
                    validOn: new Date()
                }
            });
            setEvents(oldEvents => ({...oldEvents, ...events}));
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const onSelectionChange = useCallback((index)=>{
        let day = activeDates[index];
        setSelectedDay(day);
        let date = formatDMYtoYMD(day.date);
        let dataExpiryLimit = 2 * 60 * 1000; // 2 minutes 
        let startDateIndex = Math.floor(index / 7) * 7;
        let endDateIndex = (startDateIndex + 6 < activeDates.length) ? startDateIndex+6 : activeDates.length-1;
        
        let shouldUpdate = !events[date] || (events[date] && (new Date()-events[date].validOn > dataExpiryLimit));
        if(shouldUpdate){
            fetchData(formatDMYtoYMD(activeDates[startDateIndex].date), endDateIndex-startDateIndex+1);
        }
    },[fetchData, events, activeDates]);

    if(isLoading) {
        return <FullScreenLoader/>
    }

    if(error) {
        return <div>
            There has been an error: {error}
        </div>
    }

    return (
        <div>
            <Header>
                <img className="w-6 h-6 align-middle mr-2 inline" alt="clock icon" src={require('../../resources/images/daily_calendar.svg')} />
                Daily Programme
            </Header>
            {(activeDates && activeDates.length) && (
                <DateNavigator onSelectionChange={onSelectionChange} dates={activeDates} />
            )}
            {(selectedDay && !loading) ? (
                <>
                    <Title> {convertToFormattedDate(selectedDay.date)} | {selectedDay.atSea ? "at sea" : getTranslation(selectedDay.title)} </Title>
                    <GoBySparkDetail 
                        events={events[formatDMYtoYMD(selectedDay.date)]?.events} 
                        filters={filters}
                        setFilters={setFilters}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters}
                    />
                </>
            ):(
                <FullScreenLoader />
            )}
        </div>
    );
}

export default GoBySparkOverview;