import React from "react";
import {fetchFromHub} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
import WeatherInformation from "../../components/WeatherInformation";




class WeatherOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            day: 0,
            weather: [false, false],
        };
    }

    //---

    componentDidMount() {
        let todaysDateString = moment().format("DD-MM-YYYY");
        this.props.fetchFromHub(`itinerary/weather/` + todaysDateString, 
            (response) => this.updateWeather(0, response), 
            ()=>this.updateWeather(0,[])
        );
        // this.props.fetchFromHub(`itinerary/weather/tomorrow`, (response) => this.updateWeather(1, response));
    }

    updateWeather(index, response) {
        const newWeather = [...this.state.weather];
        newWeather[index] = response;

        if(index === 0) {
            this.setState({
                loading: false,
                weather: newWeather,
            });
        }
        else {
            this.setState({
                weather: newWeather,
            });
        }
    }

    //---

    setDay = day => {
        this.setState({day});
    }

    // ---

    render() {
        if(this.state.loading) {
            return (
                <div>
                    <div className="animated fadeIn">
                        <div>
                            Loading...
                        </div>
                    </div>
                </div>
            )
        }
        const weather = Array.isArray(this.state.weather[this.state.day]) ?
            this.state.weather[this.state.day][0] :
            this.state.weather[this.state.day];

        const isWeatherUnavailable = !weather || !weather.schedule;
        const isAtSea = weather?.schedule?.atSea || false;
        let message = null;

        if (isWeatherUnavailable) {
            message = this.context.t("weather-not-available");
        } else if (isAtSea) {
            message = this.context.t("weather-at-sea");
        }

        if (message) {
            return (
                <div>
                    <div className="animated fadeIn">
                        {/* <WeatherDayPicker
                            day={this.state.day}
                            setDay={this.setDay}
                        /> */}

                        <div className="row">
                            <div className="medium-12 columns">
                                <h2 className="text-center">
                                    {message}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }



        return (
            <div>
                <div className="animated fadeIn">
                    {/* <WeatherDayPicker
                        day={this.state.day}
                        setDay={this.setDay}
                    /> */}

                    <WeatherInformation weather={weather} />
                </div>
            </div>
        );
    }

}

WeatherOverview.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(null, {fetchFromHub})(WeatherOverview);
