// TODO - extract all theme colors and other theme variables to this file and then move it to the hub
// TODO - get fontFileName to be a link from the hub and fetch that with the config
const themes = {

    riverside: {
        bodyBackground: '#eaedea',
        bodyColor: '#333',
        headerColor: '#708573',
        headerBackground: 'white',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 15%)',
        headerTextTransform: 'none',
        subheaderBackground: '#708573',
        subheaderColor: 'white',
        secondaryButtonBackground: 'white',
        secondaryButtonColor: '#708573',
        tableHeadingBackground: '#708573',
        tableHeadingColor: 'white',
        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'linear-gradient(to bottom, transparent 0%, black 100%)',
        tileTitlePadding: '30px 5px 15px',
        fontFileSrc: 'url(/fonts/Trade-Gothic-LT-Std-Extended.otf)',
        fontFileBoldSrc: 'url(/fonts/TradeGothicLTStd-BoldExt.otf)',
        buttonBackground: '#708573',
        buttonColor: 'white',
        buttonRadius: '30px',
        buttonPadding: '0.5rem 1rem',
        buttonFocusBoxShadow: '0 0 10px rgba(0,0,0,0.5)',
        tileBorderRadius: '10px',
        specialLayoutBackgroundColorOne: '#708573',
        specialLayoutColorOne: 'white',
        specialLayoutBackgroundColorTwo: 'white',
        specialLayoutColorTwo: '#708573',
        itineraryGradientColor: '#708573',
        vodCategoryHeightWidthRatio: '75%',
        footerBackground: 'rgba(112, 133, 115, 0.8)',
    },
    excellence: {
        bodyBackground: '#f0f0f0',
        headerFontWeight: 'bold',
        tileTitleFontWeight: 'bold',
        bodyColor: '#000',
        headerColor: '#63254a',
        headerBackground: 'white',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 15%)',
        headerTextTransform: 'none',
        subheaderBackground: '#63254a',
        subheaderColor: 'white',
        tableHeadingBackground: '#63254a',
        tableHeadingColor: 'white',
        tileTitleColor: 'white',
        tileTitleBackgroundColor: '#B69854',
        fontFileSrc: 'url(/fonts/segoeui.ttf)',
        fontFileBoldSrc: 'url(/fonts/seguisb.ttf)',
        buttonBackground: '#63254A',
        buttonColor: 'white',
        buttonRadius: '0px',
        buttonFocusBoxShadow: '0 0 10px rgba(0,0,0,0.5)',
        specialLayoutBackgroundColorOne: 'white',
        specialLayoutColorOne: 'black',
        specialLayoutBackgroundColorTwo: 'white',
        specialLayoutColorTwo: 'black',
        itineraryGradientColor: '#9c9595',
        vodCategoryHeightWidthRatio: '75%',
        footerBackground: 'rgb(99, 37, 74)',
        logoAlignRight: true,
        logoWideOnMobile: true,
        buttonFontWeight: 'bolder',
        headingTextColor: '#63254A',
        tileTitlePadding: "0.65rem 0.25rem",
        excursionTitleColor: '#B69854'
    },
    nicko: {
        bodyBackground: 'white',
        headerFontWeight: 'bold',
        tileTitleFontWeight: 'bold',
        bodyColor: '#333',
        headerColor: '#005284',
        headerBackground: 'white',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 15%)',
        headerTextTransform: 'none',
        subheaderBackground: 'white',
        subheaderColor: '#005284',
        tableHeadingBackground: '#005284',
        tableHeadingColor: 'white',
        tileTitleColor: '#005284',
        tileTitleBackgroundColor: 'rgba(255, 255, 255, 0.9)',
        fontFileSrc: 'url(/fonts/OpenSans-Regular.ttf)',
        fontFileBoldSrc: 'url(/fonts/OpenSans-Bold.ttf)',
        buttonBackground: '#B5131E',
        buttonColor: 'white',
        specialLayoutBackgroundColorOne: 'white',
        specialLayoutColorOne: '#005284',
        specialLayoutBackgroundColorTwo: 'white',
        specialLayoutColorTwo: '#005284',
        itineraryGradientColor: '#005284',
        vodCategoryHeightWidthRatio: '75%',
        footerBackground: 'rgba(0, 82, 132, 0.9)',
        logoWideOnMobile: true,
        buttonFontWeight: 'bolder',
    },
    fredolsen: {
        bodyBackground: 'white',
        bodyColor: '#333',
        headerColor: 'white',
        headerBackground: '#003f69',
        headerFontWeight: 'bold',
        headerTextTransform: 'none',

        subheaderColor: '#003f69',
        subheaderBackground: '#dddcd5',
        subheaderButtonBorderRadius: '0px',
        subheaderButtonBorder: 'solid 1px #003f69',
        subheaderButtonColor: '#003f69',
        subheaderButtonBackground: 'none',
        subheaderButtonIconDisplay: 'none',

        tileTitleColor: '#003f69',
        tileTitleBackgroundColor: 'rgba(221,220,213,0.95)',

        footerBackground: 'rgba(0, 63, 105, 0.90)',
        footerBorderTop: '1px solid #003f69',
        fontFileSrc: 'url(/fonts/MuseoSans_500.otf)',

        buttonBackground: '#790030',
        buttonRadius: '0px',
        buttonColor: 'white',

        defaultLinkColor: '#092A5E',

    },

    avalon: {
        bodyBackground: '#eaedea',
        bodyColor: '#000',
        headerColor: '#000',
        headerBackground: 'rgba(255, 255, 255, 0.5)',
        fontFileSrc: 'url(/fonts/Graphie-Regular.otf)',
        fontFileBoldSrc: 'url(/fonts/Graphie-SemiBold.otf)',
        tileTitleBackgroundColor: '#f1f1f1',
        tileTitleColor: "#797979",
        buttonBackground: "black",
        buttonColor: "white",
        buttonRadius: '0px',
    },
    tradewind: {
        isHeaderTallOnDS: true,
        bodyBackground: 'white',
        bodyColor: '#333',
        headerColor: 'white',
        headerBackground: 'rgb(10,50,100)',
        headerBackgroundImage: 'url("resources/images/dark-pattern-bg-large.svg")',
        headerFontWeight: 'bold',
        headerTextTransform: 'none',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 5%)',

        subheaderColor: '#003f69',
        subheaderBackground: '#dddcd5',
        subheaderButtonBorderRadius: '0px',
        subheaderButtonBorder: 'solid 1px #003f69',
        subheaderButtonColor: '#003f69',
        subheaderButtonBackground: 'none',
        subheaderButtonIconDisplay: 'none',

        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(10,50,100,0.9)',
        tileTitleFontWeight: 'bold',

        footerBackground: 'rgba(0, 63, 105, 0.90)',
        footerBorderTop: '1px solid #003f69',
        fontFileSrc: 'url(/fonts/Spartan-Regular.ttf)',

        buttonBackground: '#b4a05f',
        buttonRadius: '0px',
        buttonColor: '#051b3f',

        defaultLinkColor: '#092A5E',

        specialLayoutBackgroundColorOne: 'white',
        specialLayoutColorOne: '#002e5a',
        specialLayoutBackgroundColorTwo: '#051b3f',
        specialLayoutColorTwo: 'white',
    },
    quark: {
        bodyBackground: 'linear-gradient(180deg, rgba(12,54,98,1) 0%, rgba(57,99,141,1) 35%, rgba(61,108,154,1) 100%);',
        bodyColor: 'white',
        headerColor: 'white',
        headerBackground: 'transparent',
        headerFontWeight: 'bold',
        headerTextTransform: 'none',
        isHeaderTallOnDS: true,

        subheaderBackground: 'transparent',
        subheaderColor: 'white',
        subheaderButtonBackground: 'transparent',
        subheaderButtonColor: 'white',
        subheaderBoxShadow: '0 2px 4px 0 rgba(0,0,0,.08)',
        subheaderButtonBorder: '1px solid white',
        subheaderButtonBorderRadius: '20px',

        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(12,54,98,0.4)',
        tileTitleFontWeight: 'bold',
        tileBorderRadius: '5px',

        tileHeightWidthRatio: '50%',
        vodCategoryHeightWidthRatio: '75%',
        vodMovieHeightWidthRatio: '150%',
        vodSeriesHeightWidthRatio: '100%',

        footerBackground: 'rgba(12,54,98,0.4)',
        footerColor: 'white',
        fontFileSrc: 'url(/fonts/Arial.ttf)',
        footerBoxShadow: '0 0 4px 0 rgba(0,0,0,0.3)',

        buttonBackground: 'rgb(242, 169, 0)',
        buttonColor: '#323436',
        buttonRadius: '25px',

        defaultLinkColor: '#092A5E',

        specialLayoutBackgroundColorOne: '#5e8ab4',
        specialLayoutBackgroundColorTwo: '#5e8ab4',
        specialLayoutColorTwo: 'white',

        wayfinderBackground: 'rgba( 255, 255, 255, 0.1 )',
        wayfinderBorder: '1px solid #c3c3c3',
        wayfinderBorderRadius: '10px',

        cabinFinderMinimumLength: '2',
        cabinFinderMaximumLength: '4',
        noDailyScheduleBorder: true
    },
    kontron: {
        bodyBackground: '#eef3f7',
        bodyColor: '#0a0a0a',
        headerColor: '#002136',
        headerBackground: '#a8c4d5',
        headerBoxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',

        subheaderColor: '#002136',
        subheaderBackground: 'white',
        subheaderButtonBackground: '#002136',
        subheaderButtonBorderRadius: '0',
        subheaderBoxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',

        tileTitleColor: '#002136',
        tileTitleBackgroundColor: 'rgba(255, 255, 255, 0.9)',

        footerBackground: 'rgba(168, 196, 213, 0.85)',
        footerColor: '#002136',
        footerBoxShadow: '0 0 4px 0 rgba(0,0,0,0.3)',
        footerIconOpacity: '0.75',
        fontFileSrc: 'url(/fonts/Exo2-Variable.ttf)',

        buttonBackground: '#002136',
        buttonRadius: '0px',
        buttonColor: 'white',

        defaultLinkColor: '#092A5E',
        resourcesFolder: '/themes/kontron/',
    },
    carnival: {
        bodyBackground: '#e3f2ff',
        bodyColor: '#000',
        headerColor: '#005299',
        headerBackground: 'white',
        headerFontWeight: 'bold',

        subheaderColor: 'white',
        subheaderBackground: '#10559a',
        subheaderButtonBackground: '',
        subheaderButtonBorder: 'solid 1px #ffffff',
        subheaderButtonBorderRadius: '0px',

        tileTitleColor: '#005299',
        tileTitleFontWeight: 'bold',
        tileTitleBackgroundColor: 'rgba(255, 255, 255, 0.85)',

        footerBackground: 'rgba(16, 85, 154, 0.9)',
        fontFileSrc: 'url(/fonts/OpenSans-Regular.ttf)',

        buttonBackground: '#eb003b',
        buttonColor: 'white',
        buttonFontWeight: 'bolder',

        defaultLinkColor: '#092A5E',
    },
    tui: {
        bodyBackground: 'linear-gradient(to bottom, rgba(195, 242, 248, 1) 0%, rgba(228, 247, 252, 1) 20%, rgba(101, 177, 211, 1) 100%) no-repeat center center fixed',
        bodyColor: '#0a0a0a',
        headerColor: 'white',
        headerBackground: '#1E2C5C',
        headerFontWeight: 'bold',
        gridContainerMaxWidth: '85rem',
        subheaderColor: '#1E2C5C',
        subheaderBackground: '#70CBF4',
        subheaderButtonBackground: '#1E2C5C',
        subheaderButtonColor: 'white',

        tileTitleColor: '#244f8a',
        tileTitleBackgroundColor: 'rgba(152, 226, 255, 0.95)',
        tileBorderRadius: '10px',

        footerBackground: 'rgba(0, 61, 105, 0.8)',
        fontFileSrc: 'url(/fonts/tuitypelight-webfont-rg.ttf)',
        fontFileBoldSrc: 'url(/fonts/tuitypelight-webfont-bd.ttf)',

        buttonBackground: '#fcb712',
        buttonColor: '#0a0a0a',
        secondaryButtonBackground: '#092a5e',
        secondaryButtonColor: 'white',
        contentBoxBackground: '#DBF1FC',

        itineraryGradientColor: 'rgb(112, 203, 244)',

        specialLayoutColorOne: '#092a5e',
        wayfinderBorderRadius: '10px',
        wayfinderBoxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',

        tableHeadingBackground: '#092a5e',
        tableHeadingColor: 'white',
        tableColor: 'black',
        tableBackgroundOne: '#e2f3fe',
        tableBackgroundTwo: '#f8fafc',
        defaultLinkColor: '#092A5E',
        resourcesFolder: '/themes/tui/'
    },
    cfc: {
        bodyBackground: 'white',
        bodyColor: '#0a0a0a',
        headerColor: '#002b60',
        headerBackground: 'white',
        headerFontWeight: 'bold',
        headerBorderBottom: '1px solid #e5e9ef',
        gridContainerMaxWidth: '85rem',
        subheaderColor: '#1E2C5C',
        subheaderBackground: '#70CBF4',
        subheaderButtonBackground: '#1E2C5C',
        subheaderButtonColor: 'white',

        tileTitleColor: '#1e4a96',
        tileTitleBackgroundColor: 'rgba(255, 255, 255, 0.9)',
        tileHeightWidthRatio: '75%',

        footerBackground: 'rgba(30, 74, 150, 0.9)',
        fontFileSrc: 'url(/fonts/Lato-Regular.ttf)',
        fontFileBoldSrc: 'url(/fonts/Lato-Bold.ttf)',

        buttonBackground: 'linear-gradient(to bottom,  #1e4a96 0%,#0080cb 100%)',
        buttonColor: 'white',
        contentBoxBackground: '#DBF1FC',

        itineraryGradientColor: '#bff3ff',

        specialLayoutColorOne: '#092a5e',
        specialLayoutBackgroundColorOne: '#f1f1f1',
        
        wayfinderBackground: 'rgba(255, 255, 255, 0.9)',
        wayfinderBoxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',

        tableHeadingBackground: 'rgb(30, 74, 150)',
        tableHeadingColor: 'white',
        tableColor: 'black',
        tableBackgroundOne: '#e2f3fe',
        tableBackgroundTwo: '#f8fafc',

        defaultLinkColor: '#092A5E',
    },
    uniworld: {
        bodyBackground: '#eeeeee',
        headerFontWeight: 'bold',
        tileTitleFontWeight: 'bold',
        bodyColor: '#333',
        headerColor: '#00295b',
        headerBorderBottom: "1px solid #00295b",
        headerBackground: 'white',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 15%)',
        footerBackground: 'rgba(0, 16, 36, 0.8)',
        subheaderBackground: '#63254a',
        subheaderColor: 'white',
        secondaryButtonBackground: 'white',
        secondaryButtonColor: '#00295b',
        tableHeadingBackground: '#00295b',
        tableHeadingColor: 'white',
        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(0, 16, 36, 0.8)',
        fontFileSrc: 'url(/fonts/OpenSans-Regular.ttf)',
        fontFileBoldSrc: 'url(/fonts/OpenSans-Bold.ttf)',
        buttonBackground: '#005289',
        buttonColor: 'white',
        buttonRadius: '0px',
        specialLayoutBackgroundColorOne: '#00295b',
        specialLayoutColorOne: 'white',
        specialLayoutBackgroundColorTwo: '#00295b',
        specialLayoutColorTwo: 'white',
        itineraryGradientColor: '#916680',
        vodCategoryHeightWidthRatio: '75%',
    },
    victory: {
        isHeaderTallOnApp: true,
        bodyBackground: '#f2f7fa',
        headerFontWeight: 'bold',
        tileTitleFontWeight: 'bold',
        bodyColor: '#333',
        headerColor: '#0f2d52',
        headerBorderBottom: "1px solid #00295b",
        headerBackground: 'white',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 15%)',
        footerBackground: 'rgba(0, 16, 36, 0.9)',
        subheaderBackground: '#0f2d52',
        subheaderColor: 'white',
        secondaryButtonBackground: 'white',
        subheaderButtonBorder: '1px solid #0090b9',
        secondaryButtonColor: '#0f2d52',
        tableHeadingBackground: '#0f2d52',
        tableHeadingColor: 'white',
        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(0, 144, 185, 0.95)',
        fontFileSrc: 'url(/fonts/GothamBook.otf)',
        fontFileBoldSrc: 'url(/fonts/GothamMedium.otf)',
        buttonBackground: '#038fbe',
        buttonColor: 'white',
        buttonRadius: '0px',
        specialLayoutBackgroundColorOne: '#038fbe',
        specialLayoutColorOne: 'white',
        specialLayoutBackgroundColorTwo: '#0f2d52',
        specialLayoutColorTwo: 'white',
        itineraryGradientColor: '#038fbe',
        vodCategoryHeightWidthRatio: '75%',
        subheaderButtonIconDisplay: 'none',
        excursionTitleColor: '#038fbe',
        secondaryButtonBorder: '1px solid #0f2d52'
    },
    greenplate: {
        bodyBackground: 'linear-gradient(to bottom, #fbeacc, #79ae92)',
        headerFontWeight: 'bold',
        tileTitleFontWeight: 'bold',
        bodyColor: '#1d3853',
        headerColor: '#fbeacc',
        headerTextTransform: 'none',
        headerBackground: '#6b9c82',
        headerBoxShadow: '0 4px 10px 0 rgb(0 0 0 / 5%)',
        tileBorderRadius: '10px',
        footerBackground: '#6b9c82',
        footerColor: '#fbeacc',
        secondaryButtonBackground: '#04833e',
        secondaryButtonColor: 'white',
        tableHeadingBackground: '#04833e',
        tableHeadingColor: 'white',
        tileTitleColor: 'rgb(251, 234, 204)',
        tileTitleBackgroundColor: '#6b9c82',
        fontFileSrc: 'url(/fonts/SignikaNegative-Regular.ttf)',
        fontFileBoldSrc: 'url(/fonts/SignikaNegative-Bold.ttf)',
        buttonBackground: '#04833e',
        buttonColor: '#fbeacc',
        buttonRadius: '10px',
        specialLayoutBackgroundColorOne: '#04833e',
        specialLayoutColorOne: 'white',
        specialLayoutBackgroundColorTwo: '#04833e',
        specialLayoutColorTwo: 'white',
    },
    seachefs: {
        bodyBackground: 'white',
        bodyColor: '#0a0a0a',
        headerColor: '#002b60',
        headerBackground: 'white',
        headerFontWeight: 'bold',
        headerBorderBottom: '1px solid #07b2b2',
        gridContainerMaxWidth: '85rem',
        subheaderColor: '#1E2C5C',
        subheaderBackground: '#70CBF4',
        subheaderButtonBackground: '#1E2C5C',
        subheaderButtonColor: 'white',
        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(0, 56, 130, 0.75)',
        tileHeightWidthRatio: '75%',
        tileTitleFontWeight: 'bold',
        footerBackground: 'rgba(0, 56, 130, 0.75)',
        fontFileSrc: 'url(/fonts/Lato-Regular.ttf)',
        fontFileBoldSrc: 'url(/fonts/Lato-Bold.ttf)',
        buttonBackground: '#003882',
        buttonColor: 'white',
        contentBoxBackground: '#DBF1FC',
        itineraryGradientColor: '#bff3ff',
        specialLayoutColorOne: '#092a5e',
        specialLayoutBackgroundColorOne: '#f1f1f1',
        wayfinderBackground: 'rgba(255, 255, 255, 0.9)',
        wayfinderBoxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
        tableHeadingBackground: 'rgb(30, 74, 150)',
        tableHeadingColor: 'white',
        tableColor: 'black',
        tableBackgroundOne: '#e2f3fe',
        tableBackgroundTwo: '#f8fafc',
        defaultLinkColor: '#092A5E',
        isHeaderTallOnApp: true,
        buttonRadius: '30px',
    },
    digimaxi: {
        bodyBackground: '#81C0DF',
        bodyColor: '#262532',
        headerColor: '#FFFFFF',
        headerBackground: '#272632',
        headerFontWeight: 'bold',

        subheaderColor: '#FFFFFF',
        subheaderBackground: '#017FBF',
        subheaderButtonBackground: '#017FBF',
        subheaderButtonBorder: 'solid 1px #ffffff',
        subheaderButtonBorderRadius: '0px',

        tileTitleColor: '#FFFFFF',
        tileTitleBackgroundColor: 'rgba(1, 127, 191, 0.9)',
        tileBorderRadius: '10px',

        footerBackground: 'rgba(1, 127, 191, 0.9)',
        fontFileSrc: 'url(/fonts/Lato-Regular.ttf)',
        fontFileSrc2: 'src: url(/fonts/Lato-Regular.ttf) format("truetype");',

        buttonBackground: '#262532',
        buttonColor: '#ffffff',
        buttonRadius: '0px',
        buttonBoxShadow: '0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.2)',
    },
    roompot: {
        bodyBackground: 'white',
        bodyColor: '#292929',
        headerColor: '#292929',
        headerBackground: 'white',
        headerFontWeight: 'bolder',
        headerTextTransform: 'none',

        subheaderColor: '#292929',
        subheaderBackground: '#f0f0e7',
        subheaderButtonBorderRadius: '4px',
        subheaderButtonBorder: '2px solid #e6e7e5',
        subheaderButtonColor: '#292929',
        subheaderButtonBackground: 'white',
        subheaderButtonIconDisplay: 'none',

        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(86, 170, 174, 0.95)',
        tileTitleFontWeight: 'bold',
        boxShadow: '0 6px 12px 0 rgba(0,0,0,0.1)',

        footerBackground: 'rgba(255, 255, 255, 0.97)',
        footerBoxShadow: '0 0 4px 0 rgba(0,0,0,0.3)',
        footerColor: '#56aaae',
        fontFileSrc: 'url(/fonts/Quicksand-Regular.ttf)',

        buttonBackground: '#f5c827',
        buttonRadius: '5px',
        buttonColor: '#292929',
        buttonFontWeight: 'bolder',

        specialLayoutBackgroundColorOne: 'white',
        specialLayoutColorOne: '#56aaae',

        wayfinderBackground: 'rgba(86, 170, 174, 0.3)',
        wayfinderBorder: '1px solid #c3c3c3',
        isHeaderTallOnDS: true,

    },
    warner: {
        bodyBackground: '#ddebf0',
        bodyColor: 'black',
        headerColor: 'rgb(88, 156, 176)',
        headerBackground: 'white',
        headerFontWeight: 'bolder',
        headerTextTransform: 'none',
        logoWideOnMobile: false,

        subheaderColor: 'white',
        subheaderBackground: '#448293',
        subheaderButtonBorder: '1px solid rgb(51, 51, 51)',
        subheaderButtonColor: '#333',
        subheaderButtonBackground: 'white',
        subheaderButtonIconDisplay: 'none',

        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(68,130,147,0.8)',
        tileTitleFontWeight: 'bold',

        footerBackground: 'rgba(68,130,147,0.8)',
        footerColor: 'white',
        fontFileSrc: 'url(/fonts/ClarendonLightBT.ttf)',

        buttonBackground: '#448293',
        buttonColor: 'white',
        buttonFontWeight: 'bolder',
    },
    ambassador: {
        bodyBackground: '#F3F3F3',
        bodyColor: '#5a5a5a',
        headerColor: '#6B207D',
        headerBackground: 'white',
        headerFontWeight: 'bolder',
        headerTextTransform: 'none',
        logoWideOnMobile: true,
        centerAppHeaderLogoOnly: true,

        gridContainerMaxWidth: '85rem',

        kioskLoginIconColor: '#6B207D',

        subheaderColor: '#2F2F2F',
        subheaderBackground: '#E3DACF',
        subheaderButtonIconDisplay: 'none',
        defaultLinkColor: '#6B207D',

        tileTitleColor: 'white',
        tileTitleBackgroundColor: 'rgba(0, 0, 0, 0.8)',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
        tileTitleFontWeight: 'bold',
        bookNowBackgroundColor: "#009165",
        bookNowTextColor: "#ffffff",

        wayfinderBackground: 'rgb(240, 232, 242)',
        wayfinderBoxShadow: '0 3px 6px rgba(0,0,0,0.2)',

        specialLayoutBackgroundColorOne: '#f1ece7',
        specialLayoutColorOne: 'black',
        specialLayoutBackgroundColorTwo: '#d0bfb0',
        specialLayoutColorTwo: 'black',
        alternateSingleColumnBackgroundColors: true,

        itineraryGradientColor: 'rgb(225, 210, 229)',

        footerBackground: 'rgba(255, 255, 255, 0.9)',
        footerColor: '#6B207D',
        footerBoxShadow: '0 0 4px 0 rgba(0,0,0,0.3)',

        fontFileSrc: 'url(/fonts/Quicksand-Regular.ttf)',
        fontFileBoldSrc: 'url(/fonts/Quicksand-SemiBold.ttf)',
        altFooterHomeIcon: '/images/home_acl.png',
        altFooterBackIcon: '/images/back_acl.png',

        buttonBackground: '#6B207D',
        buttonColor: 'white',
        buttonFontWeight: 'bolder',
        activeButtonBorderColor: '#e67539',

        cabinMessagesReadColor: '#5a5a5a',
        cabinMessagesUrgentColor:'#e67539',
        cabinMessagesImportantColor: '#6B207D',
        cabinMessagesHoverBackgroundColor: '#6B207D',
        cabinMessagesHoverTextColor: '#fff',

        resourcesFolder: '/themes/ambassador/',

        // TODO - replace the values with actual desired ambassador colors & set them up for other themes if needed
        checkboxDisabledColor: "#aaa",
        checkboxCheckedBackgroundColor: "#6B207D",
        checkboxDisabledBackgroundColor: "#aaa",
        checkboxDisabledBorderColor: "#aaa",
    }
};

export function getThemeVariables(themeName) {
    if(themes[themeName]) {
        return themes[themeName];
    }

    return {};
}