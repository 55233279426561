import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import EventTile from '../../components/EventTile';
import EventModal from '../../components/EventModal';
import styled from 'styled-components';
import TextInputWithLabel from '../../components/TextInputWithLabel';
import Checkbox from '../../components/Checkbox';
import { device } from '../../resources/styles';

const FiltersContainer = styled.div`
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const Link = styled.button`
    text-decoration: underline;
    color: ${props => props.theme.defaultLinkColor || "#333333"};
    cursor: pointer;
    background: transparent;
    align-self: flex-start;
`;

const FilterButton = styled.button`
    font-weight: ${props => props.theme.buttonFontWeight || "bold"};
    padding: ${props => props.theme.buttonPadding || "1rem"};
    margin: 0.5rem 0 0.5rem;
    background: ${props => (props.disabled ? "#aaa" : props.theme.buttonBackground || "red")};
    color: ${props => props.theme.buttonColor || "white"};
    border: ${props => props.theme.buttonBorder || "none"};
    border-radius: ${props => props.theme.buttonRadius || "5px"};
    box-shadow: ${props => props.theme.buttonBoxShadow || "none"};
    text-decoration: none;
    cursor: pointer;
    position: relative;

    span {
        margin: 0rem 0.5rem;
    }

    i{
        transition: transform 200ms ease;
    }

    .turn-down{
        transform: rotate(90deg);
    }
`;

const Message = styled.h3`
    text-align: center;
    margin-bottom: 7rem;
    p{
        margin-top: 1rem;
        font-size: 1.1rem;
    }
    @media (min-width: 640px){
        font-size: 1.25rem;
    }
    @media ${device.tablet} {
        font-size: 1.5rem;
        p{
            font-size: 1.25rem;
        }
    }
`;

function GoBySparkDetail({events, filters, setFilters, showFilters, setShowFilters}) {
    const [selectedEventIndex, setSelectedEventIndex] = useState(null);
    const [levels, setLevels] = useState([]);

    const isDigitalSignage = useSelector(state => state.configuration.hubSettings.deviceType === "DigitalSignage");
    const isKiosk = useSelector(state => state.configuration.hubSettings.deviceType === "Kiosk");

    useEffect(()=>{
        updateLevels(events);
    },[events]);


    const updateLevels = (events) => {
        if(!events) return;
        const levels = events.reduce((acc, event) => [...acc, ...event.levels], []);
        let uniqueLevels = [...new Set(levels)];
        uniqueLevels = uniqueLevels.map(item => ({label: item, value: item}));
        setLevels(uniqueLevels);
    }

    const handleFiltersClick = () => {
        setShowFilters(showFilters => !showFilters);
    }

    if(!events || events?.length === 0) {
        return (
            <Message> Our Daily events are in the works! Please check back soon for updates. </Message>
        )
    }

    // Applying Filters
    let filteredEvents = events;
    if(filters){
        if(filters.searchValue){
            let searchStr = filters.searchValue.toLowerCase();
            filteredEvents = filteredEvents.filter(event => (
                (event.name + event.description).toLowerCase().includes(searchStr)
            ))
        }
        if (filters.levels?.length) {
            filteredEvents = filteredEvents.filter(event => 
                filters.levels.some(level => event.levels.includes(level))
            );
        }
    }

    return (
        <div>
            {(!isDigitalSignage && !isKiosk) ? (
                <div className='grid-container'>
                    <div className='text-right'>
                        <FilterButton onClick={handleFiltersClick}>
                            <i className='fa fa-filter'></i>
                            <span>Filters</span>
                            <i className={`fa fa-chevron-right ${showFilters ? "turn-down" : ""}`}></i>
                        </FilterButton>
                    </div>
                    {showFilters ? <EventFilters levels={levels} filters={filters} setFilters={setFilters} isDigitalSignage={isDigitalSignage}/> : null}
                </div>
            ) : null}
            {(filteredEvents?.length > 0) ? (
                <div className='grid-container'>
                    <div className={`grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 ${isDigitalSignage ? "large-up-3" : "large-up-4"}`}>
                        {filteredEvents.map((event,index) => (                
                            <EventTile event={event} handleClick={()=>setSelectedEventIndex(index)} key={event.id} isKioskOrSignage={isDigitalSignage || isKiosk}/>
                        ))}
                    </div>
                </div>
            ) : (
                <Message> 
                    No events match the filters
                    <p>Please change or clear the filters in order to see the events</p>
                </Message>
            )}
            {selectedEventIndex !== null && 
                <EventModal event={filteredEvents[selectedEventIndex]} setSelectedEventIndex={setSelectedEventIndex} />
            }
        </div>
    );
}

const EventFilters = ({levels, filters, setFilters, isDigitalSignage}) => {

    // if level exists, remove || add if not exists
    const handleLevelChange = (e) => {
        setFilters(filters => ({
            ...filters,
            levels: filters?.levels?.includes(e)
                ? filters.levels.filter(level => level !== e)
                : [...(filters?.levels || []), e]
        }));
    };

    const handleValueChange = (e) => {
        setFilters(filters =>({
            ...filters,
            searchValue: e
        }))
    }

    const clearFilters = () => {
        setFilters(null);
    }

    return (
        <FiltersContainer>
            <TextInputWithLabel 
                label = "Search"
                classNames="w-full sm:w-1/2 xl:w-1/3"
                placeholder = "Search Events"
                value={filters?.searchValue || ""}
                _onChange={handleValueChange}
            />
            <div className={`grid-x grid-margin-x grid-margin-y small-up-2 medium-up-3 ${isDigitalSignage ? "large-up-3" : "large-up-4"}`}>
                {levels.map((item,index) => (
                    <div className='cell' key={`Label - ${index}`}>
                        <Checkbox 
                            label={item.label}
                            value={item.value}
                            checked={filters?.levels?.includes(item.value) || false}
                            handleCheckboxChange={()=>handleLevelChange(item.value)}
                        />
                    </div>
                ))}
            </div>
            <Link onClick={clearFilters}>Clear Filters</Link>
        </FiltersContainer>
    )
}

export default GoBySparkDetail;