import React from "react";
import {useSelector} from 'react-redux';

function OverviewImageButtonPanel(props) {
    const isDigitalSignage = useSelector(state => state.configuration.hubSettings.deviceType === "DigitalSignage");

    return (
            <div>
                <div className={`grid-x grid-padding-x small-up-2 medium-up-3 ${isDigitalSignage ? "large-up-3" : "large-up-4"}`}>
                    {props.children}
                </div>
            </div>
        )
}

export default OverviewImageButtonPanel;