import React from "react";
import styled from "styled-components";

const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    i{
        font-size: 2rem;
    }
`;

function FullScreenLoader () {
    return(
        <LoaderWrapper>
            <i className="fa fa-spinner fa-pulse"></i>
        </LoaderWrapper>
    )
};

export default FullScreenLoader;