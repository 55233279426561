import React, {Component} from 'react';
import {PropTypes} from "prop-types";
import DetailImage from "./DetailImage";
import If from "./If";
import styled from "styled-components";
import {connect} from "react-redux";

const WeatherInfoContainer = styled.div`
    padding: 1em;
    flex-grow: 1;
    align-self: center;
`;

const WeatherBoxContainer = styled.div`
    background: ${props => props.theme.subheaderBackground || "rgb(112, 203, 244);"};
    color: ${props => props.theme.subheaderColor || "#000000"};
`;


const weatherIconMap = {
    SUN: 'sun',
    FEWCLOUDS: 'fewclouds',
    SCATTEREDCLOUDS: 'cloud',
    CLEARSKY: 'sun',
    RAIN: 'rain',
    BROKENCLOUDS: 'clouds',
    SHOWERRAIN: 'showerrain',
    THUNDERSTORM: 'thunder',
    SNOW: 'snow',
    MIST: 'mist',
    OVERCASTCLOUDS: 'overcastclouds',
};

class WeatherInformation extends Component {
    render() {
        const weather = this.props.weather;
        const {synopsis, temperature, windSpeed, windDirection, humidity, schedule:{openWeatherCityName}} = weather;
        let weatherIcon = weather.weatherIcon || 'FEWCLOUDS';
        let isWeatherDataAvailable = openWeatherCityName !== null && synopsis != null;

        return (

            <div className="grid-x grid-margin-x">
                <div className="medium-4 small-12 cell">
                    <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + weather.schedule.id}/>
                </div>
                <div className="medium-8 small-12 cell">
                    <h1>
                        {weather.schedule.title.defaultValue}
                    </h1>
                    <If test={isWeatherDataAvailable}>
                        <WeatherBoxContainer className="weather-container">
                            <div className="weather-icon-container">
                                <img
                                    className="weather-icon"
                                    src={`/images/weather/${weatherIconMap[weatherIcon]}.svg`}
                                    alt={`${weatherIconMap[weatherIcon]} icon`}
                                />
                            </div>
                            <WeatherInfoContainer>
                                {/* (synopsis != null) same as (synopsis !== null && synopsis !== undefined) */}
                                <If test={synopsis != null}> 
                                    <div>
                                        <b>{this.context.t("synopsis")}:</b> {synopsis}<br/>
                                    </div>
                                </If>
                                <If test={temperature != null}>
                                    <div>
                                        <b>{this.context.t("temperature")}:</b> {temperature}°C<br/>
                                    </div>
                                </If>
                                <If test={windSpeed != null && windDirection != null}>
                                    <div>
                                        <b>{this.context.t("wind")}:</b> {windDirection} {windSpeed}km/h<br/>
                                    </div>
                                </If>
                                <If test={humidity != null}>
                                    <div>
                                        <b>{this.context.t("humidity")}:</b> {humidity}%<br/>
                                    </div>
                                </If>
                            </WeatherInfoContainer>
                        </WeatherBoxContainer>
                    </If>
                    <If test={!isWeatherDataAvailable}>
                            <p><strong>Weather data is not available, Please check back later</strong></p>
                    </If>
                    <div>
                        <If test={weather.schedule.currency.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("currency")}:</b> {weather.schedule.currency.defaultValue}<br/>
                            </div>
                        </If>


                        <If test={weather.schedule.language.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("language")}:</b> {weather.schedule.language.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.nameOfPier.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("name_of_pier")}:</b> {weather.schedule.nameOfPier.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.localAgentName.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("ships_local_agent")}:</b> {weather.schedule.localAgentName.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.gangway.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("gangway")}:</b> {weather.schedule.gangway.defaultValue}<br/>
                            </div>
                        </If>

                        <div className="weather-schedule-field">
                            <b>{this.context.t("date")}:</b> {weather.schedule.date}<br/>
                        </div>

                        <If test={weather.schedule.arrives.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("arrives")}:</b> {weather.schedule.arrives.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.allOnBoard.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("all_on_board")}:</b> {weather.schedule.allOnBoard.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.departs.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("departs")}:</b> {weather.schedule.departs.defaultValue}<br/>
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

WeatherInformation.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(WeatherInformation);
