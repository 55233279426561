import React, { useState } from 'react';
import {connect, useSelector} from "react-redux";
import {PropTypes} from "prop-types";
import {fetchFromHub} from "../../actions/networkActions";
import If from "../../components/If";
import moment from "moment";
import {getTranslation} from "../../translations/translationUtils";
import styled from "styled-components";
import {device} from "../../resources/styles";
import {formatYmdToFullCalendarDate} from "../../utils/dateTimeUtils";
import EventTile from '../../components/EventTile';
import EventModal from '../../components/EventModal';
import DateNavigator from '../../components/DateNavigator';

const Title = styled.h3`
    font-size: 2.5rem;
`;

const Description = styled.div`
    font-size: 1.5rem;
    text-align: ${props => props.isScheduleProgram ? "center" : "left" };
`;

const DateText = styled.p`
    color: ${props => props.theme.headerColor || "#5a5a5a" };
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
`;

const EventContainer = styled.div`
  
    > div {
      padding: 0.6rem 0;
    }
    
    > div:not(:first-child) {
      border-top: ${ props => props.theme.noDailyScheduleBorder ? "none" : "1px solid darkslategray" };
    }

    @media ${device.tablet} {
      font-size: 1.3rem;
    }
  
`;

const Time = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    @media ${device.tablet} {
       width: 17%; 
    }
`;

const Event = styled.div`
    display: inline-block;
    @media ${device.tablet} {
      width: 80%;
    }
    p {
      margin-bottom: 0;
    }
`;

const Divider = styled.hr`
    max-width: 100%;
    margin: 5px 0 15px 0;
`;

const Header = styled.h1`
    color: ${props => props.theme.headerColor || "#5a5a5a" };
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
`;

class DailySchedule extends React.Component {
    constructor() {
        super();
        this.state = {
            id: null,
            initialize: true,
            title: null,
            overviewDescription: null,
            description: null,
            eventDate: null,
            events: [],
            dateString: "",
            schedules: null
        };
    }

    componentDidMount() {
        let dateString = "";
        const date = this.props.match?.params?.date?.substring(0, 10).replace(/-/g, "/") || 'today' ;
        const isScheduleProgram = this.props.location?.pathname?.startsWith("/scheduledprogram");

        // Skip fetching if events data is passed from timetable or if the url is scheduledprogram
        if (!this.props.isTimetable && !isScheduleProgram) {
            if (date === "today") {
                dateString = moment();
                this.props.fetchFromHub(`cms/dailySchedule`, this.updateEvents);
            } else {
                if (date === "tomorrow") {
                    dateString = moment().add(1, 'd');
                    this.props.fetchFromHub(`cms/dailyScheduleTomorrow`, this.updateEvents);
                } else {
                    dateString = moment(date, "DD/MM/YYYY");
                    this.props.fetchFromHub(`cms/dailyScheduleByDate?date=${date}`, this.updateEvents);
                }
            }
            this.setState({
                dateString: dateString.format("dddd, D MMMM YYYY")
            });
        }

        if(isScheduleProgram){
            this.props.fetchFromHub(`itinerary/schedules`, this.updateSchedules);
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.timetableEvents && this.props.timetableEvents !== prevProps.timetableEvents) {
            this.updateEvents(this.props.timetableEvents);
        }
    }

    isEmpty = (object) => { for(var i in object) { return false; } return true; };

    updateEvents = (response) => {
        let dailyprogram = {};
        let events = [];

        // If page is content article, filter response to match daily program uploaded for the articleId
        const id = this.props.contentId;
        if (id) {
            dailyprogram = response.find(schedule => schedule.id === id);
            events = dailyprogram.events;
        } else if (response.length > 0) {
            dailyprogram = response[response.length - 1];
            events = dailyprogram.events;
        }
        // Use timetableEvents if passed as prop, sort using only start time
        if (this.props.isTimetable && this.props.timetableEvents.length) {
            const sortedEvents = this.props.timetableEvents.sort((a, b) => {
                const timeA = new Date(`1970-01-01T${a.startTime}`);
                const timeB = new Date(`1970-01-01T${b.startTime}`);
                return timeA - timeB;
            })
            const date = this.props.timetableEvents[0].fromDate;
            this.setState({
                title: this.props.title,
                overviewDescription: "",
                description: "",
                events: sortedEvents,
                initialize: false,
                dateString: formatYmdToFullCalendarDate(date)
            });
        } else {
            // If page is content article, filter response to match daily program uploaded for the articleId
            const id = this.props.contentId;
            if (id) {
                dailyprogram = response.find(schedule => schedule.id === id);
                events = dailyprogram.events;
            } else if (response.length > 0) {
                dailyprogram = response[response.length - 1];
                events = dailyprogram.events;
            }

            this.setState({
                title: dailyprogram.title,
                overviewDescription: dailyprogram.overviewDescription,
                description: dailyprogram.description,
                events: events,
                initialize: false
            });
        }
    };

    updateSchedules = (response) => {
        this.setState(state => ({
            ...state,
            schedules: response
        }))
    }

    getReadableTime(timeString) {
        if(timeString){
            try {
                return timeString.split(':')[0] + ":" + (timeString.split(':')[1] === "0" ? "00" : timeString.split(':')[1])
            }catch(e){
                return timeString
            }
        }
    }

    removeHtmlTags(string) {
        if (!string) {
            return null
        }
        return string.replace(/<\/?[^>]+(>|$)/g, "");
    }

    onSelectionChange = (selectedDate) => {
        this.props.fetchFromHub(`cms/dailyScheduleByDate?date=${selectedDate.date}`, this.updateEvents);
        let dateString = moment(selectedDate.date, "DD/MM/YYYY");
        this.setState(state => ({
            ...state,
            dateString: dateString.format("dddd, D MMMM YYYY")
        }));
    }

    render() {
        const isScheduleProgram = this.props.location?.pathname?.startsWith("/scheduledprogram");
        return (
                <div className="margin-horizontal-9375">
                    
                    <If test={isScheduleProgram}>
                        <Header>
                            <img className="w-6 h-6 align-middle mr-2 inline" alt="clock icon" src={require('../../resources/images/daily_calendar.svg')} />
                            Scheduled Programme
                        </Header>
                        {(this.state.schedules && this.state.schedules.length) && (
                            <DateNavigator onSelectionChange={this.onSelectionChange} dates={this.state.schedules} />
                        )}
                        <DateText isScheduleProgram={true}>{this.state.dateString }</DateText>
                    </If>

                    {!this.state.initialize && this.state.events.length === 0 &&
                        <h4>{this.context.t("no_events")}</h4>
                    }

                    <If test={!this.state.initialize && this.state.events.length > 0 && !isScheduleProgram}>
                        <Title>{getTranslation(this.state.title) || this.state.title}</Title>
                        <Description>
                            <p> { this.removeHtmlTags(getTranslation(this.state.overviewDescription)) } </p>
                            <p> { this.state.dateString } </p>
                        </Description>
                        <EventContainer>
                            {
                                this.state.events.map((event, index) => {
                                    let startTime;
                                    if (event.startTimeDescription.defaultValue){
                                        startTime = getTranslation(event.startTimeDescription);
                                    } else {
                                        if(event.startTime && event.startTime !== 'Late'){
                                            startTime = this.getReadableTime(event.startTime);
                                        } else {
                                            startTime =  "";
                                        }
                                    }

                                    let endTime;
                                    if(event.endTimeDescription.defaultValue){
                                        endTime = getTranslation(event.endTimeDescription);
                                    } else {
                                        if(event.endTime && event.endTime !== 'Late'){
                                            endTime = this.getReadableTime(event.endTime);
                                        } else {
                                            endTime =  "";
                                        }
                                    }

                                    let showDividerTop = false;
                                    let showDividerBottom = false;
                                    if(event.stylingRequired === true){
                                        showDividerTop = true;
                                        showDividerBottom = true;
                                        if ( index > 0 && this.state.events[index - 1].stylingRequired === true) {
                                            showDividerTop = false;
                                        }
                                    }

                                    return (
                                            <div id={event.id} key={event.id}>
                                                { showDividerTop && <Divider /> }
                                                <Time>{startTime}<If test={startTime !== "" && endTime !== ""}> - </If>{endTime}</Time>
                                                <Event>
                                                    <div>{getTranslation(event.title)}</div>
                                                    { event.location?.defaultValue !== "" && <p>{getTranslation(event.location)} {getTranslation(event.locationDetail)}</p> }
                                                    { event.eventOverview?.defaultValue !== "" && <p>{this.removeHtmlTags(getTranslation(event.eventOverview))}</p> }
                                                </Event>
                                                { showDividerBottom && <Divider /> }
                                            </div>
                                    )
                                    })
                            }
                        </EventContainer>
                    </If>

                    <If test={isScheduleProgram}>
                        <EventContainer>
                            <ScheduledProgram  events={this.state.events}/>
                        </EventContainer>
                    </If>
                </div>
        );
    }
}


DailySchedule.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {fetchFromHub}) (DailySchedule);

const ScheduledProgram = ({events}) => {

    const [selectedEventIndex, setSelectedEventIndex] = useState(null);

    const isDigitalSignage = useSelector(state => state.configuration.hubSettings.deviceType === "DigitalSignage");

    let modifiedEvents = events.map(event => ({
        id: event.id,
        name: getTranslation(event.title),
        venue: getTranslation(event.location),
        imageId: event.id,
        start : event.startTime,
        end: event.endTime,
        eventOverview: event.eventOverview
    }))

    return (
        <div>
            <div className='grid-container'>
                <div className={`grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 ${isDigitalSignage ? "large-up-3" : "large-up-4"}`}>
                    {modifiedEvents.map((event,index) => (
                        <EventTile event={event} handleClick={()=>setSelectedEventIndex(index)} key={event.id} />
                    ))}
                </div>
            </div>

            {selectedEventIndex !== null && 
                <EventModal event={modifiedEvents[selectedEventIndex]} setSelectedEventIndex={setSelectedEventIndex} >
                    <div dangerouslySetInnerHTML={{__html: getTranslation(modifiedEvents[selectedEventIndex].eventOverview)}}/>
                </EventModal>
            }
        </div>
    );
}
