import React, {Component} from 'react';
import {connect} from 'react-redux';
import { useIdleTimer } from 'react-idle-timer'
import ReactGA from 'react-ga';
import {setLanguage} from 'redux-i18n'
import Routes from "../Routes";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {fetchFromHub} from '../actions/networkActions';
import {setModules} from '../actions/modulesActions';
import {getThemeVariables} from '../resources/themes/themes';
import styled, {ThemeProvider} from "styled-components";
import {setHubConfiguration} from "../actions/configurationActions";
import {device, GlobalStyle} from "../resources/styles";
import {getTranslation} from "../translations/translationUtils";
import {setVodFetching} from "../actions/vodActions";
import {setVodConfiguration} from "../actions/vodConfigurationActions";
import If from '../components/If';
import ThemedHeadElements from "../components/ThemedHeadElements";
import defaultPic from '../resources/images/default.png';
import DSWayfinder from "../components/DSWayfinder";
import HubEventReload from "../components/HubEventReload";

const App = styled.div`
    min-height: 100vh;
    min-width: 100%;
    
    background: ${props => props.theme.bodyBackground || "#cecece"};
    background-image: ${props => props.theme.bodyBackgroundImage};
    background-position: center; 
    background-repeat: no-repeat;
    color: ${props => props.theme.bodyColor || "#0a0a0a"};
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;

const ErrorContentContainer = styled.div`
     margin: 2em 1em;
`;

const ContentContainer = styled.div`
    @media ${device.desktop} {
        display: flex;
        justify-content: center;
        
        > div {
            flex: 1;
        }
    }
`;

class ThemedApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: null,
            errorPage: null,
            isFetching: true,
            errorMessage: null,
            disabilityOn: false,
            containerMarginTop: 0
        };
    }

    componentDidMount() {
        this.props.fetchFromHub('devices/config', this.updateConfig, this.showError);
    }

    updateConfig = (config) => {
        this.setState({isFetching: true});

        if(this.props.configuration.gaCode) {
            ReactGA.initialize({
                trackingId: this.props.configuration.gaCode,
            });
        }


        const nrLanguages = config.deviceGroup.deviceProfile.languages.length;
        // if 0 languages added to device profile, default it to english
        // caveat, hub needs to support english in that case
        if(nrLanguages === 0) {
            this.props.setLanguage("en");
        }
        else {
            // check if current lang (coming from the configuration) is supported by hub
            // if not take the first available hub langugage
            const isLangSupported = config.deviceGroup.deviceProfile.languages.find(o => o.code === this.props.lang);
            if(!isLangSupported) {
                this.props.setLanguage(config.deviceGroup.deviceProfile.languages[0].code);
            }
        }

        let themeName = "default";
        let themeVariables;
        // Add theme from themes.js
        if(config?.hub?.layoutTheme) {
            themeName = config.hub.layoutTheme;
            themeVariables = getThemeVariables(themeName)
        }
        // Add variables from Hub config and override any matches
        if (config?.hub?.uiSettings.theme) {
            themeVariables = {
                ...themeVariables,
                ...config.hub.uiSettings.theme
            }
        }

        this.setState({theme: themeVariables})

        // Check uiSettings for appExcursionVariables and override in index.css :root
        if (config.hub.uiSettings.appExcursionVariables) {
            const object = config.hub.uiSettings.appExcursionVariables;
            for (const property in object) {
                document.documentElement.style.setProperty(property, object[property]);
            }
        }

        this.props.setModules(config.deviceGroup.deviceProfile.modules);

        let pmsConfiguration = null;
        if (config.authentication && config.authentication.type) {
            pmsConfiguration = config.authentication;
        }

        let defaultImageUrl = defaultPic;
        if (config.defaultImageId) {
            defaultImageUrl = this.props.configuration.hubUrl + "blobs/image?id=" + config.defaultImageId;
        }
        this.props.setHubConfiguration({
            hubType: config.hub.hubType,
            profileId: config.deviceGroup.deviceProfile.id,
            appName: config.deviceGroup.deviceProfile.label,
            languages: config.deviceGroup.deviceProfile.languages,
            enableClock: config.deviceGroup.deviceProfile.enableClock,
            enableWayfinder: config.deviceGroup.deviceProfile.enableWayfinder,
            enableDisabilityMode: config.deviceGroup.deviceProfile.enableDisabilityMode,
            settings: config.deviceGroup.settings,
            deviceType: config.deviceGroup.deviceType,
            isDigitalSignage: !!config.deviceGroup.settings.type,
            themeName: config.hub.layoutTheme,
            uiSettings: config.hub.uiSettings,
            currency: config.hub.currency,
            osmServiceUrl: config.hub.osmServiceUrl,
            pmsConfiguration: pmsConfiguration,
            defaultImageUrl: defaultImageUrl,
            signageUrl: config.hub.signageHostname,
        });

        if(config.vod) {
            this.props.setVodConfiguration(config.vod.configuration);
        }

        this.props.setVodFetching(false);
        this.setState({isFetching: false});
    };

    showError = (errorMessage, code) => {
        this.setState({errorPage:true, isFetching: false});
        let message = "";
        if (code) {
            message += "code: "+code;
        }

        if (errorMessage) {
            if (message.length > 0) {
                message += " - "
            } else {
                message = errorMessage;
            }
            this.setState({errorMessage: message});
        }
    };

    toggleDisabilityMode = () => {
        this.setState(prevState => ({
            disabilityOn: !prevState.disabilityOn,
            containerMarginTop: 0
        }))
    }

    alterMarginTop = (amount) => {
        // Don't let marginTop get bigger than half screen height
        if ( amount > 0 && this.state.containerMarginTop > (window.innerHeight * 0.5) ) {
            return;
        }
        // Don't let marginTop get negative
        if ( amount < 0 && (this.state.containerMarginTop + amount) < 0 ) {
            this.setState({
                containerMarginTop: 0
            })
            return;
        }
        // Change margin top
        this.setState(prevState => ({
            containerMarginTop: prevState.containerMarginTop + amount
        }))
    }


    render() {
        if (this.state.isFetching){
            return null;
        }
        if(this.state.errorPage) {
            return (
                <div>
                    <ErrorContentContainer>
                        <div className="errorContainer">
                            <div className="custome-alert-danger" role="alert">
                                <p className="alert-header">
                                    Server Connection Error
                                </p>
                                <If test={this.state.errorMessage === null}>
                                    <p>Could not fetch configuration from Hub.</p>
                                </If>

                                <If test={this.state.errorMessage}>
                                    <p>{this.state.errorMessage}</p>
                                </If>
                            </div>
                        </div>
                    </ErrorContentContainer>
                </div>
            )
        }

        const appTitle = this.props.configuration.hubSettings ? getTranslation(this.props.configuration.hubSettings.appName) : null;

        return (
            <ThemeProvider theme={this.state.theme}>
                <React.Fragment>
                    <ThemedHeadElements appTitle={appTitle} resourcesFolder={this.state.theme.resourcesFolder} />
                    <GlobalStyle/>
                    {/* TODO - decide on how to handle digital signage vs app vs kiosk layouts based on requirements */}
                    {/* option 1 - have completely different layouts for each device type*/}
                    {/* option 2 - have conditionally applied styles via css classes */}
                    {/* option 3 - have styles applied here/via styled components*/}
                    {/* option 4 - sth else*/}
                    <App className="App">
                        
                        {/* Idle timer that will redirect to signage screensaver url -> only for DigitalSignage & kiosk */}
                        {
                            (
                                this.props.configuration.hubSettings.deviceType === "DigitalSignage" ||
                                this.props.configuration.hubSettings.deviceType === "Kiosk"
                            ) && this.props.configuration.hubSettings.settings.maxIdleTime &&
                                <IdleRedirect
                                    time={this.props.configuration.hubSettings.settings.maxIdleTime}
                                    url={this.props.configuration.hubSettings.signageUrl}
                                />
                        }

                        {/* reload functionality from the hub for signage and kiosk */}
                        {
                            (
                                this.props.configuration.hubSettings.deviceType === "DigitalSignage" ||
                                this.props.configuration.hubSettings.deviceType === "Kiosk"
                            ) && this.props.configuration.hubSettings.uiSettings?.enableRemoteReload &&
                            <HubEventReload
                                time={this.props.configuration.hubSettings.settings.maxIdleTime}
                                url={this.props.configuration.hubSettings.signageUrl}
                            />
                        }

                        <Header/>
                        <ContentContainer style={{marginTop: `${this.state.containerMarginTop}px`}}>
                            {this.props.configuration.hubSettings.enableWayfinder &&
                            <div className="grid-container">
                                <DSWayfinder />
                            </div>
                            }

                            <div className="grid-container mt-4 pb-16">
                                <Routes/>
                            </div>
                        </ContentContainer>
                        <Footer
                            toggleDisabilityMode={this.toggleDisabilityMode}
                            disabilityOn={this.state.disabilityOn}
                            alterMarginTop={this.alterMarginTop}
                        />
                    </App>
                </React.Fragment>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.i18nState.lang,
        configuration: state.configuration,
        isFetching: state.vod.isFetching,
    };
};

export default connect(mapStateToProps, {fetchFromHub, setModules, setHubConfiguration, setVodConfiguration, setVodFetching, setLanguage})(ThemedApp);

function IdleRedirect (props) {
    const onIdle = () => {
        window.location.assign(props.url);
    }

    useIdleTimer({
        onIdle,
        timeout: 1000 * (props.time || 600),
    })

    return null;
}