import React from 'react';
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import If from "../../components/If";
import {getTranslation} from "../../translations/translationUtils";
import {Link} from "react-router-dom";
import browserHistory from "../../utils/history";


class WhereAndWhen extends React.Component {
    constructor() {
        super();
        this.state = {
            initialize: true,
            articles: [],
        };
    }

    componentDidMount() {
        this.props.fetchFromHub(`cms/articles/whereAndWhen/`, this.updateContentArticles);
    }

    isEmpty = (object) => { for(var i in object) { return false; } return true; };

    updateContentArticles = (articles) => {
        articles.sort((a, b) => getTranslation(a.title).localeCompare(getTranslation(b.title)));
        this.setState({articles: articles, initialize: false});
    };

    handleLinkClick = (e, url) => {
        e.preventDefault();
        console.log('browser push to ', url);
        browserHistory.push(url);
    };

    render() {

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <If test={!this.state.initialize && this.state.articles.length === 0}>
                        <div className="cell medium-12 text-center">
                            <h4>{this.context.t("no_whereandwhen")}</h4>
                        </div>
                    </If>

                    <If test={!this.state.initialize && this.state.articles.length > 0}>
                    <div className="cell medium-12 text-center">
                        <table className="animated fadeIn">
                            <thead>
                            <tr className="text-center">
                                <th>{this.context.t("whereandwhen_title")}</th>
                                <th>{this.context.t("whereandwhen_deck")}</th>
                                <th>{this.context.t("whereandwhen_times")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.articles.map((article) => {

                                    const link = '/content/detail/' + article.id;

                                    return (<tr id={article.id} key={article.id}>
                                            <td><Link to={link} onClick={(e) => this.handleLinkClick(e, link)}><span className="href-style-text">{getTranslation(article.title)}</span></Link></td>
                                            <td>{article.whereAndWhen.locationInformation}</td>
                                            <td>{article.whereAndWhen.timeInformation}</td>
                                        </tr>)
                                    })
                            }
                            </tbody>
                        </table>
                    </div>
                    </If>
                </div>
            </div>
        );
    }
}


WhereAndWhen.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(WhereAndWhen);